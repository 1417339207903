import React from 'react';

import Layout from '../components/Layout';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import pic4 from '../assets/images/pic04.jpg';
import pic5 from '../assets/images/pic05.jpg';
import pic6 from '../assets/images/pic06.jpg';
import pic7 from '../assets/images/pic07.jpg';

import config from '../../config';
import Footer from '../components/Footer';
import potrebujetezamestnance from '../assets/images/goldis/potrebujetezamestnance.png';
import vyhledavanizamestnance from '../assets/images/goldis/vyhledavanizamestnance.png';
const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
 {/*       <div className="logo">
          <span className="icon fa-diamond"></span>
        </div>*/}
        <h2>Nabídka firmy Goldis Invest s.r.o.</h2>
       {/* <p>{config.subHeading}</p>*/}
      </div>
    </section>

    <section id="wrapper">
    {/*  <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={pic1} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Základní údaje o společnosti</h2>


            <div className={"half-holder"}>
              <div className={"div-left"}>
              <span>Obchodní firma: <b>GOLDIS Invest s.r.o.</b></span><br/>
                <span>Sídlo: K lindě 701/5, 190 15 Praha 9</span><br/>
                <span>Identifikační číslo: 071 18 970</span><br/>
                <span>Právní forma: Společnost s ručením omezeným</span><br/>
<br/><br/>
                <span>Statutární zástupce:</span><br/><br/>
                <span>Jednatel:  Kateryna Vepryk<br/><br/>
                Stehlíkova 1092/3, 274 01 Slaný<br/><br/>
                Česká Republika</span><br/>
                <span>Bankovní spojení: 115-7838100277/0100</span><br/>
                <span>Telefonní spojení: +420 777 716 122</span><br/>
                <span>Faxové spojení: +420 312 525 222</span><br/>
                <span>E-mail: info@goldisinvest.cz</span><br/>
                <span>Vlastnická struktura společnosti: výpis z obchodního rejstříku přiložen</span><br/>
                <span>Zástupce oprávněný jednat o nabídce: Kateryna Vepryk - jednatel <br/>
                Tel. +420 777 716 122</span>
              </div>

              <div className={"div-right"}>
                <span><b>GOLDIS Invest s.r.o.</b></span>
                <span>K lindě 701/5, 190 15 Praha 9</span>
                <span>071 18 970</span>
                <span>Společnost s ručením omezeným</span>
                  <br/>
                <span>Kateryna Vepryk</span>
                <span>Stehlíkova 1092/3, 274 01 Slaný</span>
                <span>Česká Republika</span>
                <span>115-7838100277/0100</span>
                <span>+420 777 716 122</span>
                <span>+420 312 525 222</span>
                <span>info@goldisinvest.cz</span>
              </div>

            </div>


            <a href="/#" className="special">
              Learn more
            </a>
          </div>
        </div>
      </section>*/}

      <section id="ospolecnosti" className="wrapper alt spotlight style2">
        <div className="inner" style={{textAlign:"left"}}>
      {/*    <a href="/#" className="image">
            <img src={pic2} alt="" />
          </a>*/}
          <div className="content">
            <h2 className="major">O společnosti</h2>
            <p>
           Společnost vznikla v roce 2019. Společnost zajišťuje personální obsazení volných pracovních míst v různých oborech
              (obchod, úklid, svařování a opracování kovu, výroba a montáž kovových prvků,
              potravinářský průmysl, skládování zboží a manipulace s nákladem). Společnost zajišťuje
              jak dlouhodobou spolupráci, tak dočasnou pracovní výpomoc.
            </p>
          {/*  <a href="/#" className="special">
              Learn more
            </a>*/}
          </div>
        </div>
      </section>

      <section id="potrebujetezamestnance" className="wrapper spotlight style3">
        <div className="inner" style={{textAlign: "left "}}>
          <a href="/#" className="image">
            <img src={potrebujetezamestnance} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Potřebujete zaměstnance</h2>
            <ul>
              <li>
                Potřebujete zaměstnance nebo brigádníky na výpomoc
                nebo nahodilé události, které může přinést sezóna nebo
                vypadek vlastních zaměstnanců,</li>

              <li> Nebo vyřešit odchod vlastních zaměstnanců na mateřskou
                nebo navyšení množství práce.<br/>
                Možností je najít brigádníky a nebo využít dočasné přidělení
                zaměstnanců.</li>
              <li> Ty vám zajistí naše personální agentura, která má bohaté
                zkušenosti s pomocí při hledání zaměstnanců nebo s
                outsourcingem zaměstnanců,</li>
              <li>což šetří podnikatelům jejich starosti se sháněním
                pracovníků.</li>
            </ul>
            Disponujeme obsáhlou databází zájemců o práci a můžeme tak nalézt
            ty nejvhodnější zaměstnance. <br/><br/>

            <ul>

              <li>Pokud sháníte zaměstnance na plny úvazek, agentura
                může uspořádat vyběrové řízení či pohovor a vy se můžete
                sejít už jen s vybranymi jedinci,</li>

              <li>jež disponují schopnostmi pro vykonávání hledané pracovní
                pozice. „Agentura provede jakýsi předvyběr a probere se
                životopisy.</li>
              <li>Po osobních pohovorech dokážeme s téměř stoprocentní
                jistotou vybrat jen ty uchazeče, kteří se pro danou pozici hodí.</li>
              <li>Pak už spíše závisí na osobních sympatiích a preferencích
                firmy.</li>
            </ul>
            {/*  <a href="/#" className="special">
              Learn more
            </a>*/}
          </div>
        </div>
      </section>


      <section id="vyhledavanizamestnance" className="wrapper spotlight style1">
        <div className="inner" style={{textAlign:"left"}}>
          <a href="/#" className="image">
            <img src={vyhledavanizamestnance} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Vyhledání zaměstnance dle Vašeho zadání</h2>
            <ul>
              <li> Disponujeme širokou databází zájemců o
                práci.</li>
              <li>Máme dlouhodobě předplacené inzertní
                server.</li>
              <li>Prověříme předpoklady kandidáta pro práci
                ve vaší společnosti.</li>
              <li>Testujeme jejich dovednosti, provádíme
                testy jazykové i odbornostní.</li>
              <li>Doporučíme vám pouze takové kandidáty,
                kteří prošli důkladnym vyběrem a splňují
                vámi požadovany profil</li>
            </ul>
            {/*            <a href="/#" className="special">
              Learn more
            </a>*/}
          </div>
        </div>
      </section>





      <section id="zajistimeprovas" className="wrapper alt style3">
        <div className="inner">
          {/*     <a href="/#" className="image">
            <img src={zajistime} alt="" />
          </a>*/}
          <h2 className="major">Zajistíme pro Vás</h2>
          <ul>
            <li>
              Naše agentura zajišťuje zaměstnance formou dočasného přidělování, kdy se jedná zejména
              o pracovníky z Ukrajiny, Slovenska, Bulharska, Gruzie, Moldávie, Polska, a také v případě
              specifických profesí z Asie tj Vietnam, Indie a Thajsko.</li>
            <li>
              K našim zaměstnancům přidělujme koordinátora, kdy na každou z jednotlivých směn je
              přidělen jeden člověk, který překládá a stará se o chod v rámci závodu, tj. komunikace
              s našimi, vašimi pracovníky a mistry provozu atd.</li>
            <li>
              Ubytování si zajišťujeme sami nebo popř. ve spolupráci s vaším závodem.</li>
            <li>
              Zdravotní prohlídky máme taktéž zařízené u našeho smluvního lékaře, ale můžeme, popř.
              využít i vašich služeb.</li>
            <li>
              Co se týká stravování, měli bychom zájem o poskytnutí informací, zdali je možné využít
              závodního stravování a za jakých podmínek?
            </li>
            <li>
              Víza ,naši pracovníci zejména mimo EU mají všechna platná povolení k práci na území ČR,
              popř. EU, kdy se jedná o krátkodobá 3měsíční, 6měsíční a roční víza. Pro pracovníky z Asie
              můžeme vyřídit až 2letá víza. Kdy první závozy probíhají vždy na 3 měsíce, abychom
              otestovali kvalitu zaměstnance v dané pozici a pokud se osvědčí zahájíme proces
              dlouhodobého až 2letého víza. Můžeme také zahájit spolupráci s vaším závodem v programu
              Ukrajina, který vám můžeme vyhotovit na klíč s naším provizním systémem, který se nám
              osvědčil.
            </li>
            <li>
              Ochranné pomůcky – Také by nás zajímalo, jak probíhá fakturace za OP ve vašem závodě a
              jak cca nákladově vychází tyto OP na jednoho zaměstnance.   </li>
            <li>
              Závozy – Dle objednávky z vašeho závodu si odsouhlasíme předpokládaný počet
              pracovníků v jednotlivém týdnu. Doba dodání 2 až 4 týdny počet zaměstnanců od 15 do 30.
            </li>
          </ul>


        </div>
      </section>

      <section id="financnipodminky" className="wrapper alt style1">
        <div className="inner">
          <h2 className="major">Finanční podmínky</h2>
          <p>
            Smluvní odměna bude fakturována na základě informací dodaných
            od Vaší společnosti dle vzájemné dohody.
          </p>


          <span>
            Pro všechny nabízené
            profese máme k dispozici volné pracovníky.
          </span><br/>
          <span>
         Dostupnost koordinátora denně na telefonu nebo na emailu v případě potřeby i na pracovišti.
          </span><br/>
          <span>
       Zkušenosti v oboru zprostředkování zaměstnání již od roku 2001.
          </span>

        </div>
      </section>

      <section id="reference" className="wrapper spotlight style1">
        <div className="inner">
          <div className="content">
          <h2 className="major">Reference</h2>

          <span>
           <b>- ČKD Mobilní Jeřáby, a.s., Politických věznů 1337, Slaný</b>
          </span><br/>
          <span>
       <b>- La Lorraine s.r.o., Kladno, U Kožovy hory</b>
          </span><br/>
          <span>
  <b>- QUICKSTEP ANTICORRO s.r.o, Kladno, Dubská</b>
          </span><br/>
          <span>
  <b>- LINET s.r.o, Želevčice 5, 274 01 Slaný</b>
          </span><br/>
          <span>
  <b>- T.RAD Czech s.r.o, lidická 1044, 273 51 Unhošt</b>
          </span><br/>
          <span>
  <b>- Tesco Stores ČR a.s., Vršovická 1527/68b, 100 00 Praha 10</b>
          </span>
            <br/>
            <br/>
            <br/>
          </div>
        </div>
      </section>

    </section>
    <Footer />
  </Layout>
);

export default IndexPage;
